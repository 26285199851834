<template>
    <div>
        <b-overlay>
            <b-card title="Add survey">

                <b-form-group>
                    <label>Enabled</label>
                    <b-form-checkbox v-model="addObject.enabled" switch>Enabled</b-form-checkbox>
                </b-form-group>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <b-button variant="primary" @click="addObject.questions.push({question: '', options: [], type: 0})">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add question</span>
                    </b-button>
                </b-form-group>
            </b-card>

            <div>
                <b-card v-for="(question, index) in addObject.questions" :key="index">
                    <b-row>
                        <b-col cols="9" sm="5">
                            <b-form-group >
                                <label>Type</label>
                                <v-select
                                    v-model="addObject.questions[index].type"
                                    :reduce="type => type.id"
                                    label="name"
                                    :options="types"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="2" sm="7" align="right">
                            <b-button variant="danger" @click="addObject.questions.splice(index, 1)" class="mr-1 mt-2">
                                <feather-icon icon="Trash2Icon"/>
                            </b-button>
                        </b-col>
                    </b-row>

                    <div v-if="(addObject.questions[index].type === 1) || (addObject.questions[index].type === 4)">
                        <b-row>
                            <b-col>
                                <b-form-group>
                                    <label>Question</label>
                                    <b-form-input :value="addObject.questions[index].question" v-on:input="(value) => addObject.questions[index].question = value"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group>
                            <b-button variant="primary" @click="addObject.questions[index].options.push('')">
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Add option</span>
                            </b-button>
                        </b-form-group>
                        <div v-for="(option, index1) in addObject.questions[index].options" :key="index1">
                            <b-row>
                                <b-col cols="9" sm="5">
                                    <b-form-group>
                                        <label>Option</label>
                                        <b-form-input :value="addObject.questions[index].options[index1]" v-on:input="(value) => addObject.questions[index].options[index1] = value"/>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" sm="2">
                                    <b-button variant="danger" @click="addObject.questions[index].options.splice(index1, 1)" class="mr-1 mt-2">
                                        <feather-icon icon="Trash2Icon"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </div>

                    <div v-else>
                        <b-row>
                            <b-col>
                                <b-form-group>
                                    <label>Question</label>
                                    <b-form-input :value="addObject.questions[index].question" v-on:input="(value) => addObject.questions[index].question = value"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </div>

            <b-card>
                <b-button variant="primary" @click="addSurvey">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </b-card>

        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BOverlay, BFormGroup, BFormInput, BFormCheckbox, BButton, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            vSelect,
            BCard,
            BOverlay,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BButton,
            BRow,
            BCol
        },
        data() {
            return {
                addObject: {
                    name: '',
                    enabled: true,
                    questions: []
                },
                types: [{id: 0, name:'Single answer question (Yes/No)'}, {id: 1, name:'Multiple answer question'}, {id: 2, name:'Free text question'}, {id: 3, name:'Number question'}, {id: 4, name:'Multiple answer question - select multiple'}, {id: 5, name:'Date question'}, {id: 6, name:'Date and time question'}],
                surveyAddedId: ''
            }
        },
        methods: {
            addSurvey() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/survey', this.addObject)
                addPromise.then(function(response) {
                    thisIns.surveyAddedId = response.data.id
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([addPromise]).finally(function() {
                    thisIns.addQuestions()
                })
            },
            addQuestions() {
                const thisIns = this
                const addQuestionsPromise = this.$http.put(`/api/management/v1/survey/${thisIns.surveyAddedId}/questions`, this.addObject.questions)
                addQuestionsPromise.then(function() {
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([addQuestionsPromise]).finally(function() {
                    thisIns.$printSuccess('Survey added')
                    thisIns.$router.go(-1)
                })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
